// Using one centimeter as the base unit, convert it to the desired unit
function getMultiplier(value, unit) {
  switch (unit.toLowerCase()) {
    case 'cm':
      return value;
    case 'in':
      return value * 0.3937007874;
    default:
      return false;
  }
}

const convertCentimeter = (amount, unit) => getMultiplier(amount, unit);
const convertInch = (amount, unit) =>
  getMultiplier(amount / 0.3937007874, unit);

export function lengthConverter(amount, fromUnit, toUnit) {
  if (typeof amount !== 'number') return null;

  let value = false;

  switch (fromUnit) {
    case 'cm':
      value = convertCentimeter(amount, toUnit);
      break;
    case 'in':
      value = convertInch(amount, toUnit);
      break;
    default:
      value = false;
      break;
  }

  if (!value) return null;

  return value.toFixed();
}
