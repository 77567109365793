// vendors
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

// utils
import { generateSoftGoodAccessoryUrl } from '../../../lib/generateSoftGoodAccessoryUrl';
import getLocaleString from '../../../lib/getLocaleString';
import getLocaleBlocks from '../../../lib/getLocaleBlocks';
import { weightConverter } from '../../utils/weightConverter';
import { lengthConverter } from '../../utils/lengthConverter';
import capitalize from '../../utils/capitalize';
import pluralize from '../../utils/pluralize';

// components
import BlockContent from '../../components/BlockContent';
import BuySection from '../../components/BuySection';
import Gallery from '../../components/Gallery';
import Heading from '../../components/Heading';
import Layout from '../../components/Layout';
import VideoGallery from '../../components/VideoGallery';
import Sidebar from '../../components/Sidebar/Sidebar';
import BuyForm from '../../components/BuyForm';
import { messages as variantMessages } from '../../components/ProductVariants/Variant';

// hooks
import useScrollThreshold from '../../hooks/useScrollThreshold';

// styles
import * as styles from './SoftGoodAccessory.module.css';

function generateTranslations(langKey, slug) {
  return ['fr', 'en']
    .filter((locale) => locale !== langKey)
    .map((locale) => ({
      locale,
      url: generateSoftGoodAccessoryUrl(locale, slug),
    }));
}

const messages = defineMessages({
  gallery: { defaultMessage: 'Galerie' },
  galleryAncherHashtag: { defaultMessage: 'galerie' },
  videos: { defaultMessage: 'Vidéos' },
  videosAncherHashtag: { defaultMessage: 'videos' },
  details: { defaultMessage: 'Détails' },
  detailsAncherHashtag: { defaultMessage: 'details' },
  purchase: { defaultMessage: 'Achat' },
  purchaseAncherHashtag: { defaultMessage: 'achat' },
});

const getSizeLabel = (value, locale = 'en') => {
  const size = {
    extraSmall: { en: 'Extra small', fr: 'Très petit' },
    small: { en: 'Small', fr: 'Petit' },
    medium: { en: 'Medium', fr: 'Moyen' },
    large: { en: 'Large', fr: 'Grand' },
    extraLarge: { en: 'Extra large', fr: 'Très grand' },
  };

  return size[value][locale];
};

const SoftGoodAccessoryTemplate = ({
  data: { sanitySoftGoodAccessory },
  pageContext,
}) => {
  const { langKey } = pageContext;

  const [asideIsVisible, setAsideIsVisible] = React.useState(false);
  const [openActionModal, setOpenActionModal] = React.useState(false);

  const mainSectionRef = React.useRef(null);

  const { formatMessage } = useIntl();

  const isVisible = useScrollThreshold(140);

  const data = React.useMemo(() => {
    const name = getLocaleString(sanitySoftGoodAccessory.name, langKey);
    const subName = getLocaleString(sanitySoftGoodAccessory.subName, langKey);

    const description = sanitySoftGoodAccessory.body ? (
      <BlockContent
        blocks={getLocaleBlocks(sanitySoftGoodAccessory.body, langKey)}
      />
    ) : (
      ''
    );

    const featuredImage = {
      url: sanitySoftGoodAccessory.featuredImage.asset.url,
      gatsbyImageData:
        sanitySoftGoodAccessory.featuredImage.asset.localFile.childImageSharp,
    };

    const url = generateSoftGoodAccessoryUrl(
      langKey,
      sanitySoftGoodAccessory.slug.current
    );

    const translations = generateTranslations(
      langKey,
      sanitySoftGoodAccessory.slug.current
    );

    const gallery = (sanitySoftGoodAccessory?.gallery?.images || []).map(
      ({ image }) => {
        const gatsbyImageData = getImage(image.asset.localFile.childImageSharp);

        return {
          gatsbyImageData,
        };
      }
    );

    const videos = (sanitySoftGoodAccessory.videos || []).map(({ url }) => url);

    const normalizeVariants =
      sanitySoftGoodAccessory.variants?.map((variant) => {
        const { name, sizeValues, customValues } = variant;

        const sizes =
          sizeValues.map((size) => ({
            ...size,
            value: {
              en: getSizeLabel(size.value, 'en'),
              fr: getSizeLabel(size.value, 'fr'),
            },
            available: true,
            temporarilyOutOfStock: false,
          })) || [];

        const customs =
          customValues.map((custom) => ({
            ...custom,
            available: true,
            temporarilyOutOfStock: false,
          })) || [];

        return {
          name,
          label: formatMessage(variantMessages[name]),
          options: name === 'size' ? sizes : customs,
          selectedValue: null,
        };
      }) || [];

    const dimensions = {
      length: sanitySoftGoodAccessory.dimensions?.length?.toString(),
      width: sanitySoftGoodAccessory.dimensions?.width?.toString(),
      height: sanitySoftGoodAccessory.dimensions?.height?.toString(),
    };

    if (sanitySoftGoodAccessory.dimensions?.unit === 'in') {
      dimensions.length = lengthConverter(
        sanitySoftGoodAccessory.dimensions?.length,
        'in',
        'cm'
      );
      dimensions.width = lengthConverter(
        sanitySoftGoodAccessory.dimensions?.width,
        'in',
        'cm'
      );
      dimensions.height = lengthConverter(
        sanitySoftGoodAccessory.dimensions?.height,
        'in',
        'cm'
      );
    }

    const weight =
      sanitySoftGoodAccessory.weight?.unit === 'g'
        ? sanitySoftGoodAccessory.weight?.value?.toString()
        : weightConverter(sanitySoftGoodAccessory.weight?.value, 'lb', 'g');

    const materials = getLocaleString(
      sanitySoftGoodAccessory.materials,
      langKey
    );

    return {
      id: sanitySoftGoodAccessory.slug.current,
      name,
      subName,
      featuredImage,
      description,
      gallery,
      videos,
      url,
      translations,
      dimensions,
      weight,
      materials,
      landscapeMode: sanitySoftGoodAccessory.landscapeMode,
      slug: sanitySoftGoodAccessory.slug.current,
      variants: normalizeVariants,
      price: sanitySoftGoodAccessory.price,
      onDemandOnly: sanitySoftGoodAccessory.onDemandOnly,
    };
  }, [formatMessage, langKey, sanitySoftGoodAccessory]);

  const featuredImage = getImage(data.featuredImage.gatsbyImageData);

  const handleCloseModal = useCallback(() => setOpenActionModal(false), []);

  const handleOpenModal = useCallback(() => setOpenActionModal(true), []);

  const formSubject = useMemo(() => {
    return formatMessage(
      { defaultMessage: 'Je suis intéressé par ce produit — {name}' },
      { name: data.name }
    );
  }, [data.name, formatMessage]);

  React.useLayoutEffect(() => {
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (asideIsVisible !== entry.isIntersecting) {
          setAsideIsVisible(entry.isIntersecting);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0,
      rootMargin: '70px',
    });

    observer.observe(mainSectionRef.current);
  }, [asideIsVisible, mainSectionRef]);

  return (
    <Layout
      translations={data.translations}
      logoOptions={{ inverted: true, fixed: true, isVisible }}
    >
      <article className="space-y-32 pt-[110px] md:pt-[170px]">
        <header className="-mb-8 container">
          <div
            className={cx([
              'w-full relative aspect-[5/7]',
              { 'aspect-[7/5]': data.landscapeMode },
            ])}
          >
            <GatsbyImage
              image={featuredImage}
              className="!absolute inset-0"
              layout="fill"
              role="presentation"
              alt=""
            />
          </div>

          <div>
            <h1
              className={classNames([
                'relative font-sans font-thin uppercase text-center mt-2',
                styles.title,
              ])}
            >
              {data.name}

              {data.subName && (
                <span
                  className={classNames([
                    'block font-serif text-[.5em] normal-case font-semibold italic',
                    styles.subTitle,
                  ])}
                >
                  {data.subName}
                </span>
              )}
            </h1>
          </div>
        </header>

        <main ref={mainSectionRef} className="space-y-32">
          {data.description && (
            <div className="max-w-prose mx-auto space-y-6 text-center">
              {React.cloneElement(data.description)}
            </div>
          )}

          {(data.gallery || []).length > 0 && (
            <section
              id={formatMessage(messages.galleryAncherHashtag)}
              className="scroll-mt-24 container"
            >
              <Heading level={2} className="sr-only">
                <FormattedMessage {...messages.gallery} />
              </Heading>

              <Gallery
                className="wide-content-contained"
                pictures={data.gallery}
              />
            </section>
          )}

          <section
            id={formatMessage(messages.detailsAncherHashtag)}
            className="-mb-8 container bg-white py-container"
          >
            <Heading level={2} className="!mt-0">
              <FormattedMessage {...messages.details} />
            </Heading>

            <ul className="grid grid-cols-[repeat(auto-fill,minmax(200px,306px))] gap-4">
              <li className="break-inside-avoid">
                <b>
                  <FormattedMessage defaultMessage="Composition :" />
                </b>
                <br />
                {data.materials || `N/A`}
              </li>

              {data.variants?.map((variant) => {
                const totalOptions = variant.options.length;
                const message = formatMessage(
                  { defaultMessage: 'offert{suffix}{plural} :' },
                  {
                    suffix: variant.name === 'style' ? '' : 'e',
                    plural: [1, -1].includes(Number(totalOptions)) ? '' : 's',
                  }
                );

                return (
                  <li key={variant.name} className="break-inside-avoid">
                    <b>
                      {capitalize(pluralize(totalOptions, variant.label))}{' '}
                      {message}
                    </b>

                    {variant.options?.map((option) => (
                      <>
                        <br />
                        {capitalize(
                          getLocaleString(option.value, langKey)
                        )}{' '}
                        {option.description && (
                          <span>
                            ({getLocaleString(option.description, langKey)})
                          </span>
                        )}
                      </>
                    ))}
                  </li>
                );
              })}
            </ul>
          </section>

          {data.videos && data.videos.length > 0 && (
            <section
              id={formatMessage(messages.videosAncherHashtag)}
              className="scroll-mt-24 container"
            >
              <Heading level={2}>
                <FormattedMessage {...messages.videos} />
              </Heading>

              <VideoGallery
                videos={data.videos}
                className="wide-content-contained"
              />
            </section>
          )}
        </main>

        <BuySection
          data={data}
          isVisible={asideIsVisible}
          onClick={handleOpenModal}
        />
      </article>

      <Sidebar open={openActionModal} onClose={handleCloseModal}>
        <div className="max-w-md">
          <Heading level={2}>
            <FormattedMessage defaultMessage="Acheter sur demande" />
          </Heading>

          <BuyForm productName={data.name} subject={formSubject} />
        </div>
      </Sidebar>
    </Layout>
  );
};

export default SoftGoodAccessoryTemplate;

export const query = graphql`
  query ($id: String!) {
    sanitySoftGoodAccessory(id: { eq: $id }) {
      id
      name {
        en
        fr
      }
      subName {
        en
        fr
      }
      body {
        _rawEn(resolveReferences: { maxDepth: 10 })
        _rawFr(resolveReferences: { maxDepth: 10 })
      }
      price
      slug {
        current
      }
      gallery {
        images {
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760)
                }
              }
            }
          }
        }
      }
      featuredImage {
        asset {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1280, placeholder: BLURRED)
            }
          }
        }
      }
      landscapeMode
      videos {
        url
        service
      }
      variants {
        ... on SanityVariant {
          name
          customValues {
            ... on SanityCustomVariant {
              value {
                fr
                en
              }
              extraPrice
              description {
                fr
                en
              }
              additionalInfo {
                fr
                en
              }
            }
          }
          sizeValues {
            ... on SanitySizeVariant {
              value
              extraPrice
            }
          }
        }
      }
      materials {
        en
        fr
      }
      onDemandOnly
      dimensions {
        length
        width
        height
        unit
      }
      weight {
        unit
        value
      }
    }
  }
`;
