// Using one gram as the base unit, convert it to the desired unit
function getMultiplier(value, unit) {
  switch (unit.toLowerCase()) {
    case 'g':
      return value;
    case 'kg':
      return value * 0.001;
    case 'lb':
      return value * 0.00220462;
    default:
      return false;
  }
}

const convertGram = (amount, unit) => getMultiplier(amount, unit);
const convertKilogram = (amount, unit) => getMultiplier(amount * 1000, unit);
const convertPound = (amount, unit) => getMultiplier(amount / 0.00220462, unit);

export function weightConverter(amount, fromUnit, toUnit) {
  if (typeof amount !== 'number') return null;

  let value = false;

  switch (fromUnit) {
    case 'g':
      value = convertGram(amount, toUnit);
      break;
    case 'kg':
      value = convertKilogram(amount, toUnit);
      break;
    case 'lb':
      value = convertPound(amount, toUnit);
      break;
    default:
      value = false;
      break;
  }

  if (!value) return null;

  return value.toFixed();
}
