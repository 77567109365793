import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import * as styles from './Variant.module.css';
import getLocaleString from '../../../../lib/getLocaleString';

const cx = classNames.bind(styles);

export const messages = defineMessages({
  color: { defaultMessage: 'couleur' },
  dimension: { defaultMessage: 'dimension' },
  size: { defaultMessage: 'taille' },
  style: { defaultMessage: 'style' },
});

// export const sizeLabels = {
//   extraSmall: defineMessage({ defaultMessage: 'Très petit' }),
//   small: defineMessage({ defaultMessage: 'Petit' }),
//   medium: defineMessage({ defaultMessage: 'Moyen' }),
//   large: defineMessage({ defaultMessage: 'Grand' }),
//   extraLarge: defineMessage({ defaultMessage: 'Très grand' }),
// };

const VariantOption = ({
  name,
  value,
  description,
  checked,
  inverted,
  available,
  onChange,
}) => {
  const { locale } = useIntl();

  const computedValue = useMemo(() => {
    return value?.en || value?.fr;
  }, [value]);

  const labelValue = useMemo(() => {
    if (!value) return;
    return value[locale] || value?.fr || value?.en;
  }, [locale, value]);

  const descriptionValue = useMemo(() => {
    if (!description) return;
    return description[locale] || description?.fr || description?.en;
  }, [description, locale]);

  return (
    <>
      <input
        type="radio"
        id={`${name}-${computedValue}`}
        name={name}
        value={computedValue}
        checked={checked}
        className="peer sr-only"
        onChange={onChange}
      />

      <label
        tabIndex="-1"
        htmlFor={`${name}-${computedValue}`}
        className={cx([
          'relative block min-w-[50px] h-[50px] px-2 leading-none text-sm border border-indigo cursor-pointer text-indigo motion-safe:transition focus:bg-slate-100 hover:bg-slate-100 peer-focus:outline peer-focus:outline-2 peer-focus:outline-transparent peer-checked:bg-indigo peer-checked:text-white',
          {
            'text-white border-white hover:!bg-indigo-700 peer-checked:!bg-white peer-checked:!text-indigo':
              inverted,
            unavailableInverted:
              (!available && inverted && !checked) ||
              (!available && checked && !inverted),
            unavailable:
              (!available && inverted && checked) ||
              (!available && !checked && !inverted),
          },
        ])}
      >
        <span className="flex items-center justify-center w-full h-full pt-1 text-size-inherit font-weight-inherit text-inherit capitalize select-none">
          {labelValue} {descriptionValue ? `(${descriptionValue})` : ''}
        </span>
      </label>
    </>
  );
};

export const Variant = ({
  tag,
  name,
  options,
  value,
  inverted,
  isLast,
  onChange,
}) => {
  const { formatMessage, locale } = useIntl();

  const Tag = tag || 'h2';

  const emitChange = useCallback(
    (event) => {
      if (typeof onChange === 'function') onChange(event);
    },
    [onChange]
  );

  return (
    <fieldset
      className={cx([
        'border-none p-0 min-w-0',
        {
          default: true,
          inverted: inverted,
        },
      ])}
    >
      <legend
        className={cx([
          'table max-w-full p-0 text-sm font-medium whitespace-normal text-indigo',
          { 'text-white': inverted },
        ])}
      >
        <Tag className="m-0 text-size-inherit font-weight-inherit text-inherit capitalize">
          <FormattedMessage
            defaultMessage="{name}:"
            values={{
              name: formatMessage(messages[name]),
            }}
          />
        </Tag>
      </legend>

      <ul className="flex flex-wrap gap-3">
        {options?.map((option) => (
          <li key={`${name}-${option.value?.en}`}>
            <VariantOption
              name={name}
              value={option.value}
              description={option.description}
              checked={value?.en === option.value?.en}
              inverted={inverted}
              available={option.available}
              onChange={emitChange}
            />
          </li>
        ))}
      </ul>

      {options
        ?.filter((option) => value?.en === option.value?.en)
        .filter((option) => option.additionalInfo)
        .map((option) => (
          <p
            className={cx([
              'text-xs text-indigo mt-2',
              { '!text-white': inverted },
            ])}
          >
            {getLocaleString(option.additionalInfo, locale)}
          </p>
        ))}

      {isLast &&
        options
          ?.filter((option) => value?.en === option.value?.en)
          .map((option) => (
            <p
              className={cx([
                'text-xs text-indigo mt-3',
                { '!text-white': inverted },
              ])}
            >
              {option.available && !option.temporarilyOutOfStock && (
                <span
                  className={cx([
                    'text-xs text-green-700 font-medium mt-3',
                    { '!text-green-400': inverted },
                  ])}
                >
                  <FormattedMessage defaultMessage="En stock" />
                </span>
              )}

              {option.available && option.temporarilyOutOfStock && (
                <>
                  <span
                    className={cx([
                      'text-xs text-orange-600 font-medium mt-3',
                      { '!text-orange-400': inverted },
                    ])}
                  >
                    <FormattedMessage defaultMessage="Temporairement indisponible." />
                  </span>
                  <span className="block">
                    <FormattedMessage
                      defaultMessage="Commandez maintenant et Michel vous informera par courriel
                      lorsqu'il aura une date de livraison estimée pour cet
                      article."
                    />
                  </span>
                </>
              )}

              {!option.available && (
                <span
                  className={cx([
                    'text-xs text-red-700 font-medium mt-3',
                    { '!text-red-300': inverted },
                  ])}
                >
                  <FormattedMessage defaultMessage="Actuellement non disponible" />
                </span>
              )}
            </p>
          ))}

      {name === 'size' && (
        <a
          className={cx([
            'block underline font-medium text-xs text-indigo mt-3',
            { 'text-white': inverted },
          ])}
          href={`https://www.jameo.com/${
            locale === 'fr' ? 'fr/grandeurs/unisexe' : 'sizes/unisex'
          }`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage defaultMessage="Voir charte des tailles" />
        </a>
      )}
    </fieldset>
  );
};

Variant.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.shape({
        en: PropTypes.string,
        fr: PropTypes.string,
      }),
      description: PropTypes.shape({
        en: PropTypes.string,
        fr: PropTypes.string,
      }),
      extraPrice: PropTypes.number,
      available: PropTypes.bool,
      temporarilyOutOfStock: PropTypes.bool,
    })
  ).isRequired,
  value: PropTypes.shape({
    en: PropTypes.string,
    fr: PropTypes.string,
  }),
  tag: PropTypes.string,
  inverted: PropTypes.bool,
  isLast: PropTypes.bool,
  onChange: PropTypes.func,
};
Variant.defaultProps = {
  tag: 'h2',
  inverted: false,
  isLast: false,
  value: undefined,
  onChange: undefined,
};
